<template>
	<v-sheet class="report" style="height: calc(100vh - 88px)">
		<v-col md="12" class="py-1 my-auto text-right listing-right">
			<template>
				<v-menu
					bottom
					left
					origin="center center"
					transition="slide-y-transition"
					rounded="0"
					offset-y
					max-height="400px"
					max-width="500"
					:close-on-content-click="false"
					content-class="white-background"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							depressed
							color="blue darken-4"
							class="text-white"
							tile
							:disabled="pageLoading"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon>mdi-table-edit</v-icon>
						</v-btn>
					</template>
					<Draggable
						tag="ul"
						v-model="draggableThead"
						class="draggable-group"
						handle=".draggable-drag-icon"
						v-on:change="updateTable('project')"
					>
						<template v-for="cols in draggableThead">
							<li
								style="min-width: 300px !important"
								class="draggable-group-item"
								:key="cols.key"
								v-if="!cols.checkbox"
							>
								<v-checkbox
									dense
									v-model="draggableTheadShow"
									v-bind:value="cols.key"
									:label="cols.name"
									:disabled="cols.fixed || pageLoading"
									color="blue"
									hide-details
									class="mt-0 mb-0"
									v-on:change="updateTableVisiblity('asset')"
								></v-checkbox>
								<v-icon
									v-if="!pageLoading && !cols.fixed"
									class="draggable-action draggable-drag-icon"
									right
									color="blue"
									>mdi-drag</v-icon
								>
								<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
							</li>
						</template>
					</Draggable>
				</v-menu>
				<v-btn
					depressed
					:disabled="pageLoading"
					color="blue darken-4"
					class="text-white"
					tile
					v-on:click="advanceSearch = true"
				>
					<v-icon>mdi-filter</v-icon>
				</v-btn>
			</template>
		</v-col>

		<SearchCriteria
			v-if="searchEnabled()"
			:search-fields="listingFilter"
			search-string="Project #, Name, Company Name, Display Name and Email Address"
			v-on:advance-search="advanceSearch = true"
			v-on:close-search="resetSearch"
		></SearchCriteria>
		<Table
			:page-loading="pageLoading"
			type="product-stock-history"
			detail-route="product-stock-history"
			v-on:reload:content="filterRows"
			delete-note="All transactions of this project will also be deleted."
		></Table>
		<ExportDialogNew
			endpoint="project/export"
			title="Export Project"
			:cols="draggableThead"
			:export-dialog.sync="exportDialogNew"
			v-on:close="exportDialogNew = false"
		></ExportDialogNew>
		<ImportDialog
			endpoint="project/import?type=project"
			title="Projects"
			:import-dialog="importDialog"
			v-on:close="importDialog = false"
		></ImportDialog>
		<FilterDialog
			title="product stock history"
			:filter-dialog="advanceSearch"
			:btx-filter="listingFilter"
			:btx-query="btxQuery"
			v-on:do-search="doAdvanceSearch"
			v-on:close="advanceSearch = false"
		>
		</FilterDialog>
	</v-sheet>
</template>
<script>
import ListingMixin from "@/core/mixins/listing.mixin";

export default {
	name: "product-stock-history-listing",
	title: "Product Stock History Listing",
	mixins: [ListingMixin],
	components: {},
	methods: {
		updateDialog(uuid) {
			this.uuid = uuid;
			this.dialog = true;
		},
	},
	data() {
		return {
			uuid: null,
			dialog: false,
			pageTitle: "product Stock history",
			pageBreadcrumbs: [{ text: "product-stock-history-listing", disabled: true }],
			endpoint: "report/product-stock-history",
			defaultFilter: {},
		};
	},
};
</script>
